<template>
  <base-form-item :field="field">
    <div v-loading="loading">
      <div
          class="notes-list lineHeight--initial"
          v-if="notes.length > 0"
      >
        <div
            :key="note.id"
            @mouseleave="showButtonsItemId = null"
            @mouseover="showButtonsItemId = note.id"
            v-for="note in notes"
        >
          <el-card body-style="padding: 10px 15px">
            <div
                class="d-flex justify-content-between align-items-center"
                slot="header"
                style="height: 28px"
            >
              <span><b>{{ note.username }}</b></span>
              <transition name="el-fade-in-linear">
                <span
                    v-if="showButtonsItemId === note.id && (
                ifHasPermission(['pm-edit-notes'], data.project_id) || (ifHasPermission(['pm-edit-own-notes'], data.project_id) && note.user_id === user.id) ||
                ifHasPermission(['pm-delete-notes']) || (ifHasPermission(['pm-delete-own-notes']) && note.user_id === user.id)
                )"
                >
                  <template v-if="currentEditNote.id !== note.id">
                    <el-button
                        @click="editNote(note.id, note.content)"
                        size="mini"
                        type="info"
                        v-if="ifHasPermission(['pm-edit-notes'], data.project_id) || (ifHasPermission(['pm-edit-own-notes'], data.project_id) && note.user_id === user.id)"
                    >{{ $t('system.edit') }}</el-button>
                    <el-button
                        @click="deleteNote(note.id)"
                        size="mini"
                        type="danger"
                        v-if="ifHasPermission(['pm-delete-notes'], data.project_id) || (ifHasPermission(['pm-delete-own-notes'], data.project_id) && note.user_id === user.id)"
                    >{{ $t('system.delete') }}</el-button>
                  </template>
                  <template v-else>
                    <el-button
                        @click="clearCurrentEditNote"
                        size="mini"
                        type="info"
                    >{{ $t('system.cancel') }}</el-button>
                    <el-button
                        @click="updateNote"
                        size="mini"
                        type="success"
                    >{{ $t('system.update') }}</el-button>
                  </template>
                </span>
                <span v-else>
                  {{ formatDate(note.created_at) }}
                </span>
              </transition>
            </div>
            <div class="text item">
              <el-input
                  autosize
                  type="textarea"
                  v-if="currentEditNote.id === note.id && (ifHasPermission(['pm-edit-notes'], data.project_id) || (ifHasPermission(['pm-edit-own-notes'], data.project_id) && note.user_id === user.id))"
                  v-model="currentEditNote.content"
              ></el-input>
              <span
                  class="preformatted"
                  style="word-wrap: break-word"
                  v-else
              >{{ note.content }}</span>
            </div>
          </el-card>
        </div>
        <el-card
            class="pagination-container mt-3"
            shadow="never"
            v-if="totalRows > pageSizes[0]"
        >
          <el-pagination
              :current-page="params.page"
              :page-size="params.perPage"
              :page-sizes="pageSizes"
              :total="totalRows"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
      </div>
      <div
          class="el-form-text"
          v-else
      >
        <span class="no-date-title">{{ $t('system.no_data') }}</span>
      </div>
    </div>
  </base-form-item>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert2';
import {mapGetters} from 'vuex';
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';
import EventBus from '../../../../../eventBus';
import notification from '../../../../../notification/notify';

export default {
  mixins: [abstractForm],
  name: 'Notes_Index',
  components: {BaseFormItem},
  data() {
    return {
      loading: true,
      showButtonsItemId: null,
      currentEditNote: {
        id: null,
        content: '',
      },
      notes: [],
      params: {
        page: 1,
        perPage: 10,
      },
      totalRows: null,
      pageSizes: [10, 30, 50, 100, 300],
    };
  },
  mounted() {
    EventBus.$on('FETCH_NOTES', () => {
      this.fetchData();
    });
    this.fetchData();
  },
  beforeDestroy() {
    EventBus.$off('FETCH_NOTES');
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.params.issueId = this.data.id;
      axios.get('notes', {
        params: this.params,
      }).then((response) => {
        this.notes = response.data.data;
        this.totalRows = response.data.meta.total;
        this.loading = false;
      });
    },
    editNote(id, content) {
      this.currentEditNote.id = id;
      this.currentEditNote.content = content;
    },
    updateNote() {
      axios.put(`note/${this.currentEditNote.id}`, {content: this.currentEditNote.content})
          .then(() => {
            this.clearCurrentEditNote();
            notification.notify(
                this.$t('notify.success'),
                this.$t('system.note_updated'),
                'success');
            EventBus.$emit('FETCH_ACTIVITIES');
            this.fetchData();
          }).catch((error) => {
        notification.notify(this.$t('notify.error'),
            error.response.data.error,
            'error');
      });
    },
    clearCurrentEditNote() {
      this.currentEditNote.id = null;
      this.currentEditNote.content = '';
    },
    deleteNote(id) {
      swal.fire({
        title: this.$t('system.if_you_sure'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
        focusCancel: true,
      }).then((result) => {
        if (result.value) {
          axios.delete(`note/${id}`).then(() => {
            notification.notify(
                this.$t('notify.success'),
                this.$t('system.note_deleted'),
                'success');
            this.fetchData();
          }).catch((error) => {
            notification.notify(this.$t('notify.error'),
                error.response.data.error,
                'error');
          });
        }
      });
    },
    formatDate(date) {
      return moment.utc(date).local().format('YYYY-MM-DD HH:mm');
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.params.perPage = val;
      this.fetchData();
    },
    next() {
      this.params.page++;
      this.fetchData();
    },
    prev() {
      this.params.page--;
      this.fetchData();
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.notes-list {
  .el-card {
    margin: 5px 0;

    .el-card__header {
      padding: 7px 15px !important;
    }
  }
}
</style>
